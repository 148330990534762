body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiOutlinedInput-input {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.MuiInputLabel-outlined {
  transform: translate(14px, 13px) scale(1) !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}

.Mui-error {
  color: rgba(0, 0, 0, 0.54) !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #af0061 !important;
}

.rdrDefinedRangesWrapper {
  width: 0 !important;
}

.rdrPprevButton i {
  border-width: 8px 10px 8px 8px !important;
  border-color: transparent #af0061 transparent transparent !important;
}

.rdrNextPrevButton {
  background: rgba(0, 0, 0, 0) !important;
}

.rdrNextPrevButton {
  background: rgba(0, 0, 0, 0) !important;
}

.rdrNextButton i {
  border-width: 8px 10px 8px 8px !important;
  border-color: transparent transparent transparent #af0061 !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.MuiButton-root {
  text-transform: none !important;
}
